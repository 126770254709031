import React from "react";
import { theme } from "../designSystemVariables";
import { ProductMetadata } from "../../../helpers/catalog/ProductMetadataProvider";

type MetadataType = NonNullable<ProductMetadata["type"]>;

export const metadataStyles: Record<MetadataType, React.CSSProperties> = {
  on_sale: {
    backgroundColor: "#ffc0df",
    color: "#68123d",
  },
  low_stock: {
    backgroundColor: "#ffd9c0",
    color: "#683412",
  },
  out_of_stock: {
    backgroundColor: "#e1e4ea",
    color: "#222530",
  },
  inactive: {
    backgroundColor: "#FAD2D5",
    color: "#92363E",
  },
  new_in: {
    backgroundColor: "#c0d5ff",
    color: "#122368",
  },
  bestseller: {
    backgroundColor: "#cac0ff",
    color: "#351a75",
  },
  trending: {
    backgroundColor: "#c2f5ee",
    color: "#0b463e",
  },
  slow_mover: {
    backgroundColor: "#ffecc0",
    color: "#624c18",
  },
  star_product: {
    backgroundColor: "#FFDF00",
    color: "#4F3600",
  },
};
