import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { getData } from "src/api/authorizedApi";
import { CollectionType } from "src/api/types";
import { DeleteCategoryMutationFunc } from "../../components/categories_v2_v3_common/Category/useDeleteCategory";
import { SaveConfigCallback } from "../../components/categories_v2_v3_common/CategoryConfigModal/CategoryConfigModalProvider";
import {
  CategoriesNavigationFunction,
  CategoryNavigationFunction,
} from "../../components/categories_v2_v3_common/types";
import { useAlerts } from "../../components/storybook/Alert/useAlerts";
import queryClient from "../../queries/client";
import { QueryId, getQueryKey } from "../../queries/queries";
import { AccessValidator } from "../../router/ProtectedRoute";
import { AuthorizedApi } from "../hooks/app/useAuthorizedApi";
import {
  GetCollectionCallback,
  GetCollectionConfig,
} from "../hooks/categories_v2_v3_common/useCategory/useCategory";
import useScollections from "../hooks/useScollections";

export const querySalt = "categoryV3";
export const DEFAULT_MARKET_GROUP = "global";

export const useCreateSCollectionMutation = (
  api: AuthorizedApi | null,
  merchantId: string
) => {
  const { addAlert } = useAlerts();
  const mutation = useMutation({
    mutationFn: async (collection: {
      title: string;
      queryId?: string;
      type: CollectionType;
      parentId?: string | undefined;
      mainProductIds?: string[] | undefined;
      pinnedMainProductIds?: string[] | undefined;
      coverImageUrl?: string | undefined;
    }) => {
      if (!api || !merchantId) return;
      try {
        const response = await api.POST(
          "/api/v0/listing/{merchant_id}/scollections",
          {
            body: {
              collection_type: collection.type,
              title: collection.title,
              show_in_filter: false,
              appear_in_quicklinks: false,
              display_breadcrumb: false,
              filters: [],
              query_id: collection.queryId,
              parent_id: collection.parentId,
              show_in_search_suggestions: false,
              main_product_ids: collection.mainProductIds,
              pinned_main_product_ids: collection.pinnedMainProductIds,
              cover_image_url: collection.coverImageUrl,
            },
            params: {
              path: {
                merchant_id: merchantId,
              },
            },
          }
        );

        const data = getData(response);

        return data;
      } catch (error) {
        addAlert({
          message: "Error creating collection, contact depict for more details",
          type: "danger",
          autohide: true,
          id: "creating-collection-error",
        });
      }
    },
  });

  return mutation;
};

export const accessValidator: AccessValidator = (u, m) => true;

export const getSCollection = async (
  api: AuthorizedApi,
  merchantId: string,
  categoryId: string,
  marketGroup: string = DEFAULT_MARKET_GROUP,
  market?: string
) => {
  const sCollection = await queryClient.fetchQuery({
    queryKey: getQueryKey(
      QueryId.GetSCollection,
      merchantId,
      categoryId,
      marketGroup,
      market ?? null
    ),
    queryFn: async () => {
      const response = await api.GET(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}",
        {
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: categoryId,
            },
            query: {
              market_group_id: marketGroup,
              market: market,
            },
          },
        }
      );

      return getData(response);
    },

    gcTime: 1 * 5 * 1000,
    staleTime: 1 * 60 * 1000,
  });

  return {
    sCollection,
  };
};

export const useGetCategoryCB = (
  api: AuthorizedApi | null,
  marketGroup: string,
  market: string | null
) => {
  return useCallback<GetCollectionCallback>(
    async (merchantId, collectionId) => {
      if (!api) throw new Error("api is not defined");

      const { sCollection } = await getSCollection(
        api,
        merchantId,
        collectionId,
        marketGroup,
        market ?? undefined
      );

      return {
        collection: sCollection,
      };
    },
    [api, market, marketGroup]
  );
};

export const useNavigationPaths = () => {
  const getCategoryPath = useCallback<CategoryNavigationFunction>(
    (merchandId: string, categoryId: string) => {
      return `/${merchandId}/categories-v3/${encodeURIComponent(categoryId)}`;
    },
    []
  );

  const getCategoryViewAllPath = useCallback<CategoryNavigationFunction>(
    (merchandId: string, categoryId: string) => {
      return `/${merchandId}/categories-v3/${encodeURIComponent(
        categoryId
      )}/view-all`;
    },
    []
  );

  const getCategoriesPath = useCallback<CategoriesNavigationFunction>(
    (merchandId: string) => {
      return `/${merchandId}/categories-v3`;
    },
    []
  );

  const navigationPaths = useMemo(() => {
    return {
      getCategoryPath: getCategoryPath,
      getCategoryViewAllPath: getCategoryViewAllPath,
      getCategoriesPath,
    };
  }, [getCategoriesPath, getCategoryPath, getCategoryViewAllPath]);

  return navigationPaths;
};

export const useSaveConfigCB = (
  api: AuthorizedApi | null,
  marketGroup: string = DEFAULT_MARKET_GROUP,
  market: string | null = null
) => {
  const client = useQueryClient();

  return useCallback<SaveConfigCallback>(
    async (
      merchantId,
      categoryId,
      titles,
      queryId,
      config,
      pinned_collection_ids
    ) => {
      await api?.PUT(
        "/api/v0/listing/{merchant_id}/scollections/category/{collection_id}",
        {
          body: {
            localized_titles: titles ? titles : undefined,
            // TODO: edit URIs, not deprecated query ID
            query_id: queryId || undefined,
            appear_in_quicklinks: config.appear_in_quicklinks,
            display_breadcrumb: config.display_breadcrumb,
            show_in_filter: config.show_in_filter,
            show_in_search_suggestions: config.show_in_search_suggestions,
            pinned_collection_ids: pinned_collection_ids,
          },
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: categoryId,
            },
          },
        }
      );

      await client.invalidateQueries({
        queryKey: getQueryKey(
          QueryId.GetCategory,
          merchantId,
          categoryId,
          marketGroup,
          market
        ),
      });
    },
    [api, client, market, marketGroup]
  );
};

export const useDeleteCategoryCB = (
  api: AuthorizedApi | null,
  marketGroup: string = DEFAULT_MARKET_GROUP,
  market: string | null = null
) => {
  const client = useQueryClient();

  const { refetch } = useScollections();

  return useCallback<DeleteCategoryMutationFunc>(
    async (merchantId, categoryId) => {
      await api?.DELETE(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}",
        {
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: categoryId,
            },
          },
        }
      );

      await client.invalidateQueries({
        queryKey: getQueryKey(
          QueryId.GetCategory,
          merchantId,
          categoryId,
          marketGroup,
          market
        ),
      });
      await refetch();
    },
    [api, client, market, marketGroup, refetch]
  );
};

export const useGetCategoryConfig = (
  api: AuthorizedApi | null,
  marketGroup: string,
  market: string | null
) => {
  const getCategoryCB = useGetCategoryCB(api, marketGroup, market);

  const getCategoryConfig = useMemo<GetCollectionConfig>(() => {
    return {
      callback: getCategoryCB,
      enabled: !!api,
      queryKeySalt: `${querySalt}-${marketGroup}-${market ?? ""}`,
    };
  }, [api, getCategoryCB, market, marketGroup]);

  return getCategoryConfig;
};

export const useGetCategoryProductIds = (
  api: AuthorizedApi | null,
  marketGroup: string,
  market?: string
) => {
  const getAllCategoryProductIds = useCallback<
    (merchantId: string, categoryId: string) => Promise<string[]>
  >(
    async (merchantId, categoryId) => {
      if (!api) throw new Error("api is not defined");

      const { sCollection } = await getSCollection(
        api,
        merchantId,
        categoryId,
        marketGroup,
        market
      );

      return sCollection.manual_main_product_ids;
    },
    [api, market, marketGroup]
  );

  return getAllCategoryProductIds;
};
